//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-168:_7310,_536,_9758,_9764,_8736,_1294,_5144,_5128;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-168')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-168', "_7310,_536,_9758,_9764,_8736,_1294,_5144,_5128");
        }
      }catch (ex) {
        console.error(ex);
      }